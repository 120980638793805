import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot from react-dom/client
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Footer from "./footer"; // Import the Footer component with a lowercase "f"

const root = createRoot(document.getElementById("root")); // Use createRoot from react-dom/client
root.render(
  <BrowserRouter>
    <App />
    <Footer />
  </BrowserRouter>
);
