import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import "./App.css";

const BACKEND_ENDPOINT =
  "https://m3iqrqe2mkk36f5lxtbhuzvkeq0kijoi.lambda-url.eu-west-2.on.aws";

function App() {
  const [longUrl, setLongUrl] = useState("");
  const [shortUrl, setShortUrl] = useState("");
  const [error, setError] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;
    if (path !== "/") {
      const key = path.substring(1);

      axios
        .get(`${BACKEND_ENDPOINT}/${key}`)
        .then((response) => {
          const { data } = response;
          const targetURL = data.targetURL;
          window.location.replace(`http://${targetURL}`);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (longUrl.length < 5) {
      setShortUrl("");
      setError("Please enter a valid URL");
      return;
    }

    try {
      const response = await axios.post(`${BACKEND_ENDPOINT}/shorten`, {
        url: longUrl,
      });
      const { shortUrl } = response.data;
      setShortUrl(shortUrl);
      setError("");
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText("https://sjlee.co.kr/" + shortUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <h1 className="text-center">URL Shortener</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="longUrl">
              <Form.Control
                type="text"
                value={longUrl}
                onChange={(event) => setLongUrl(event.target.value)}
                placeholder="Enter a long URL"
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Shorten
            </Button>
          </Form>
          {error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}
          {shortUrl && (
            <div className="mt-3">
              <h3>Shortened URL:</h3>
              <div className="short-url-container">
                <a href={shortUrl} target="_blank" rel="noopener noreferrer">
                  {"https://sjlee.co.kr/" + shortUrl}
                </a>
                <button className="copy-icon" onClick={handleCopyToClipboard}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-clipboard"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 1a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2h2a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2zm5-1a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v1h4V0zM4 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5v1z" />
                    <path
                      fillRule="evenodd"
                      d="M11.5 6a.5.5 0 0 1 .5.5V15a.5.5 0 0 1-.5.5a.5.5 0 0 1-.5-.5V6.5a.5.5 0 0 1 .5-.5zM4.5 6a.5.5 0 0 1 .5.5V15a.5.5 0 0 1-.5.5a.5.5 0 0 1-.5-.5V6.5a.5.5 0 0 1 .5-.5z"
                    />
                  </svg>
                </button>

                {copied && <div className="copy-message">Copied!</div>}
              </div>
            </div>
          )}

          <a
            href="https://www.digiocean.co.kr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Show me the money
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
