// Footer.js
import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import "bootstrap/dist/css/bootstrap.min.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="social-icons">
        {/* Use Link for internal links */}
        <Link
          to="https://www.instagram.com/yoyogracie/"
          className="social-icon"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram"></i>
        </Link>
        <Link
          to="https://www.linkedin.com/in/ehshin0126/"
          className="social-icon"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-linkedin-in"></i>
        </Link>
        <Link
          to="https://www.youtube.com/@GraceShin0126"
          className="social-icon"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-youtube"></i>
        </Link>
      </div>
    </footer>
  );
}

export default Footer;
